import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import { renderElements } from "../components/elements"
import SEO from "../components/seo"

export const query = graphql`
  query($slug: String!) {
    wpPage(slug: { eq: $slug }) {
        id
        slug
        title
        notification
        renderContent
    }
  }
`
const Page = props => {

    let pageInfo = null;
    if(props.data.wpPage.notification !== ''){
        pageInfo = JSON.parse(props.data.wpPage.notification)
    }

    return (
        <Layout
            contentObjects={JSON.parse(props.data.wpPage.renderContent)}
            pageInfo={pageInfo}
        >
            <SEO title={props.data.wpPage.title} />
            {renderElements(props.data.wpPage.renderContent)}
        </Layout>
    )
}

export default Page